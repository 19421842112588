import fetch from 'isomorphic-fetch';
import { API_URL } from 'helpers/apiURL';

/* ----------------------- List ----------------------- */
export const requestList = () => ({ type: 'REQUEST_FAMILY_LIST' });

export const fetchList = () => {
  return function(dispatch) {
    dispatch(requestList());

    return fetch(`${API_URL}/family`)
      .then(response => response.json())
      .then(json => dispatch(receiveList(json)));
  };
};

export const receiveList = response => ({
  type: 'RECEIVE_FAMILY_LIST',
  list: response.data
});

/* ----------------------- Fetch ----------------------- */
export const requestFamily = () => ({ type: 'REQUEST_FAMILY' });

export const fetchFamily = id => {
  return function(dispatch) {
    dispatch(requestFamily());

    return fetch(`${API_URL}/family/${id}`)
      .then(response => response.json())
      .then(json => dispatch(receiveFamily(json)));
  };
};

export const receiveFamily = response => ({
  type: 'RECEIVE_FAMILY',
  family: response.data
});
