import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Button from 'components/atoms/Button';
import './VehicleCard.scss';

import { currency } from 'helpers/format';
import { getImage as getVehicleImage } from 'helpers/vehicle';

class VehicleCard extends Component {
  render() {
    const { size, vehicle, link, actionLabel } = this.props;

    let vehicleImage = getVehicleImage(vehicle.family || vehicle.name);

    return (
      <div
        className={'vehicleCard ' + (size ? 'vehicleCard-' + size + ' ' : '')}
      >
        <div className="vehicleCard__container">
          <div className="vehicleCard__title">
            <h3>{vehicle.name}</h3>
          </div>
          {vehicle.start_price && (
            <div className="vehicleCard__price">
              <h5>Desde</h5>
              <h2>{currency(vehicle.start_price, vehicle.currency)}</h2>
            </div>
          )}

          <div className="vehicleCard__priceDetail">
            {vehicle.price && (
              <div className="vehicleCard__listPrice">
                <div className="vehicleCard__discount__item">
                  Precio de lista: <strong>{currency(vehicle.price, vehicle.currency)}</strong>
                </div>
              </div>
            )}

            {vehicle.special_discount > 0 && (
              <div className="vehicleCard__discount__item">
                Con descuento especial de{' '}
                <strong>-{currency(vehicle.special_discount, vehicle.currency)}</strong>
              </div>
            )}

            {vehicle.special_discount === '0' && vehicle.web_discount > 0 && (
              <div className="vehicleCard__discount__item">
                Con descuento web de{' '}
                <strong>-{currency(vehicle.web_discount, vehicle.currency)}</strong>
              </div>
            )}

            {vehicle.model_year_discount > 0 && (
              <div className="vehicleCard__discount__item">
                Descuento por MY:{' '}
                <strong>-{currency(vehicle.model_year_discount, vehicle.currency)}</strong>
              </div>
            )}
          </div>

          <div className="vehicleCard__image">
            <img src={vehicleImage} alt="Model" />
          </div>
          <div className="vehicleCard__actions">
            <NavLink to={link || '#'}>
              <Button arrow="true" color="gray" type="full">
                {actionLabel || 'Conocelo'}
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default VehicleCard;
