import { Component } from 'react';
import { connect } from 'react-redux';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import PlanCard from 'components/molecules/PlanCard';
import Loader from 'components/atoms/Loader';
import PlanCouponValidate from 'components/organisms/PlanCoupon/Validate';
import { fetchList } from 'actions/plans';
import { url } from 'helpers/format';

import './Plans.scss';

class Plans extends Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const { loading, plans } = this.props;

    return (
      <div>
        <MobileNavigation />
        <Header />
        <div className="plans container">
          <h1>Planes de Ahorro</h1>
          <PlanCouponValidate />
          <div className="plansList">
            {loading && <Loader />}
            {plans.map(plan => (
              <PlanCard
                key={plan.id}
                plan={plan}
                subscribeLink={`/planes-de-ahorro/suscribirse/${plan.id}/${url(
                  plan.name
                )}`}
                bookLink={`/planes-de-ahorro/reservar/${plan.id}/${url(
                  plan.name
                )}`}
                detailLink={`/planes-de-ahorro/detalle/${plan.id}/${url(
                  plan.name
                )}`}
              />
            ))}
          </div>
        </div>
        <div className="container">
          <small>* No incluye seguro del vehículo.</small>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.plans.loading,
    plans: state.plans.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetch: () => dispatch(fetchList())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
