import { Link } from 'react-router-dom';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import MobileNavigation from 'components/molecules/MobileNavigation';

export default () => (
  <div className="planBuy">
    <MobileNavigation />
    <Header />
    <div className="unitDetail container">
      <div className="unitDetail__hero">
        <div className="unitDetail__hero__data">
          <h4>Plan de ahorro inexistente o fuera de vigencia.</h4>
        </div>
      </div>
      <div className="unitDetail__buy">
        <div className="unitDetail__buy__step">
          <Link to="/">Regresar</Link>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
