import { useEffect, useState } from 'react';
import 'whatwg-fetch';
import FormTextField from 'components/molecules/FormTextField';
import Button from 'components/atoms/Button';
import DisplayPlanCoupon from './DisplayPlanCoupon';
import './ValidateAndDisplay.scss';

const ValidateAndDisplay = ({ onValidate, code }) => {
  const [userCode, setCode] = useState(code || '');
  const [plan, setPlan] = useState(null);
  const [error, setError] = useState(false);

  function handleChange(e) {
    setCode(e.currentTarget.value);
  }

  function handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    setError(false);

    fetch(`${process.env.REACT_APP_INTERNAL_API_URL}/plans/validate`, {
      method: 'POST',
      body: JSON.stringify({ code: userCode }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        if (!response) {
          setError(true);
          return;
        }

        setPlan(response);
        onValidate(code, response);
      })
      .catch(e => {
        setError(true);

        console.error(e);
      });
  }

  useEffect(() => {
    if (code) {
      handleSubmit();
    }
  }, [code]); // eslint-disable-line

  return (
    <>
      {!plan && (
        <div id="planBenefitsBox">
          <form onSubmit={handleSubmit}>
            <h5>¿Tenés un código de beneficios?</h5>
            <FormTextField
              name="code"
              value={userCode}
              onChange={handleChange}
              status={error ? 'error' : undefined}
              error={
                error ? 'El código es incorrecto o ha vencido.' : undefined
              }
            />
            <Button disabled={!userCode} type="submit">
              Validar
            </Button>
          </form>
        </div>
      )}
      {plan !== null && <DisplayPlanCoupon planCoupon={plan} />}
    </>
  );
};

export default ValidateAndDisplay;
