export function number(input) {
  return input ? input.toLocaleString('es-AR') : '';
}

export function currency(price, currency) {
  if (!currency) {
    return currencyARS(price);
  }
  return currency === 'usd' ? currencyUSD(price) : currencyARS(price);
}

export function currencyARS(input) {
  return input
    ? parseFloat(parseFloat(input).toFixed(2)).toLocaleString('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumSignificantDigits: 2
      })
    : '';
}

export function currencyUSD(price) {
  if (!price) {
    return "";
  }

  return parseFloat(parseFloat(price).toFixed(2)).toLocaleString("es-AR", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
};

export function url(input) {
  return input ? encodeURI(input.toLowerCase().replace(/[\s/]+/g, '-')) : '';
}
