import fetch from 'isomorphic-fetch';
import { API_URL, INTERNAL_API_URL } from 'helpers/apiURL';

/* ----------------------- Fetch ----------------------- */
export const requestStock = () => ({ type: 'REQUEST_STOCK' });

export const resetStock = () => ({ type: 'RESET_STOCK' });

export const fetchStock = (familyId, subfamilyId) => {
  return function(dispatch) {
    dispatch(requestStock());

    return fetch(`${API_URL}/family/${familyId}/${subfamilyId}/stock`)
      .then(response => response.json())
      .then(json => dispatch(receiveStock(json)));
  };
};

export const receiveStock = response => ({
  type: 'RECEIVE_STOCK',
  stock: response.data
});

/* ------------------- Request price ------------------- */
export const requestPrice = () => ({ type: 'REQUEST_PRICE' });

export const sendPriceRequest = data => {
  return function(dispatch) {
    dispatch(requestPrice());

    return fetch(`${INTERNAL_API_URL}/request-price`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then(response => dispatch(priceRequested()))
      .catch(() => dispatch(priceRequestError()));
  };
};

export const priceRequested = () => ({
  type: 'PRICE_REQUESTED'
});

export const priceRequestError = response => ({
  type: 'PRICE_REQUEST_ERROR'
});

/* ------------------- Offers ------------------- */
export const requestOffers = () => ({ type: 'REQUEST_OFFERS' });

export const fetchOffers = () => {
  return function(dispatch) {
    dispatch(requestOffers());

    return fetch(`${API_URL}/model/offers`, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(json => dispatch(offersReceived(json)))
      .catch(() => dispatch(fetchOffersError()));
  };
};

export const offersReceived = response => ({
  type: 'OFFERS_RECEIVED',
  offers: response.data
});

export const fetchOffersError = () => ({
  type: 'REQUEST_OFFERS_ERROR',
  offers: null
});

/* ------------------- Stock ------------------- */
export const requestStockItem = () => ({ type: 'REQUEST_ITEM' });

export const fetchStockItem = id => {
  return function(dispatch) {
    dispatch(requestStockItem());

    return fetch(`${API_URL}/stock/${id}`, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(json => dispatch(stockItemReceived(json)))
      .catch(() => dispatch(fetchStockItemError()));
  };
};

export const stockItemReceived = response => ({
  type: 'ITEM_RECEIVED',
  item: response.data && response.data.length ? response.data[0] : false
});

export const fetchStockItemError = () => ({
  type: 'REQUEST_ITEM_ERROR',
  item: null
});

export const usdRateRequested = () => ({ type: 'RATE_REQUESTED' });

export const fetchUsdRate = id => {
  return function(dispatch) {
    dispatch(usdRateRequested());

    return fetch('https://sistema.yacopini.com.ar/intranet/webservice/v2/baic/usd/rate', {
      method: 'GET'
    })
      .then(response => response.json())
      .then(json => dispatch(usdRateReceived(json)))
      .catch(console.error);
  };
};

export const usdRateReceived = response => ({
  type: 'RATE_RECEIVED',
  payload: response.value ? response.value : null
});

/* ------------------- Purchase ------------------- */
export const stockItemPurchaseRequested = () => ({ type: 'REQUEST_PURCHASE' });

export const purchaseStockItem = (id, data) => {
  return function(dispatch) {
    dispatch(stockItemPurchaseRequested());

    return fetch(`${INTERNAL_API_URL}/purchase/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    })
      .then(response => response.json())
      .then(response => {
        if (!response || !response.metadata) {
          dispatch(
            stockItemPurchaseError(
              'Error desconocido. Por favor reintentar más tarde.'
            )
          );
        }

        if (response.metadata.success) {
          dispatch(stockItemPurchased());
        }

        if (response.metadata.success !== true) {
          dispatch(stockItemPurchaseError(response.metadata.message));
        }
      })
      .catch(() => dispatch(stockItemPurchaseError()));
  };
};

export const initPurchase = response => ({
  type: 'INIT_PURCHASE'
});

export const stockItemPurchased = response => ({
  type: 'PURCHASE_SUCCESSFUL'
});

export const stockItemPurchaseError = error => {
  return {
    type: 'REQUEST_PURCHASE_ERROR',
    error: error
  };
};

/* ------------------- Promotion ------------------- */
export const initPromotionCode = response => ({
  type: 'PROMOTION_CODE_INIT'
});

export const checkPromotionCode = data => {
  return dispatch => {
    dispatch(promotionCodeCheckRequested());

    return fetch(
      `https://territorioyacopini.com.ar/api/promotion?code=${data.code}&family=${data.family}&chassis=${data.chassis}&brand_id=4`
    )
      .then(response => response.json())
      .then(json => {
        dispatch(promotionCodeCheckSuccessful(json.data));
      })
      .catch(() => dispatch(promotionCodeCheckFailed()));
  };
};

const promotionCodeCheckRequested = response => ({
  type: 'PROMOTION_CODE_REQUESTED'
});

const promotionCodeCheckFailed = response => ({
  type: 'PROMOTION_CODE_REQUEST_FAILED'
});

const promotionCodeCheckSuccessful = promotion => ({
  type: 'PROMOTION_CODE_SUCCESSFUL',
  promotion
});

/* --------------- Promotion discovery --------------- */
export const discoverPromotionCode = data => {
  return dispatch => {
    dispatch(promotionCodeDiscoverRequested());

    return fetch(
      `${INTERNAL_API_URL}/promotion/discover?family=${data.family}&chassis=${data.chassis}&brand_id=4`
    )
      .then(response => response.json())
      .then(json => {
        dispatch(promotionCodeDiscoverSuccessful(json.data));
      });
  };
};

const promotionCodeDiscoverRequested = response => ({
  type: 'PROMOTION_CODE_DISCOVER_REQUESTED'
});

const promotionCodeDiscoverSuccessful = promotion => ({
  type: 'PROMOTION_CODE_DISCOVER_SUCCESSFUL',
  promotion
});
