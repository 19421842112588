import { Component } from 'react';
import 'whatwg-fetch';

import FormFieldset from 'components/molecules/FormFieldset';
import FormTextField from 'components/molecules/FormTextField';
import FormTextArea from 'components/molecules/FormTextArea';
import FormActions from 'components/molecules/FormActions';
import Button from 'components/atoms/Button';
import FormContainer from 'components/organisms/FormContainer';

export default class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sent: false,
      error: false,
      sending: false,
      form: {}
    };
  }

  send(data) {
    const formData = new FormData();

    for (let key in data) {
      formData.set(key, data[key]);
    }

    fetch('//territorioyacopini.com.ar/api/v2/nissan/contact', {
      method: 'POST',
      body: formData
    })
      .then(() => {
        this.setState({ sent: true, sending: false });
      })
      .catch(() => {
        this.setState({ error: true, sending: false });
      });
  }

  canSend() {
    return (
      this.state.form.name &&
      this.state.form.lastname &&
      this.state.form.cellphone
    );
  }

  handleOnChange(e) {
    const form = { ...this.state.form, [e.target.name]: e.target.value };

    this.setState(Object.assign({}, this.state, { form }));
  }

  render() {
    const { error, sent, sending } = this.state;

    return (
      <FormContainer arrow>
        <h3>Escribinos un mensaje</h3>
        <p>Dejanos tus datos y tus dudas y te responderemos a la brevedad.</p>

        {sent && (
          <strong>
            Recibimos tu mensaje y te estaremos contactando a la brevedad.{' '}
            <br />
          </strong>
        )}

        {error && (
          <strong>
            No pudimos enviar el mensaje. Por favor intentá nuevamente más
            tarde.
            <br />
          </strong>
        )}

        {sending && <em>Enviando...</em>}

        {!error && !sent && (
          <div>
            <FormFieldset>
              <FormTextField
                label="Nombre"
                name="name"
                onChange={this.handleOnChange.bind(this)}
              />
              <FormTextField
                label="Apellido"
                name="lastname"
                onChange={this.handleOnChange.bind(this)}
              />
              <FormTextField
                label="Email"
                name="email"
                onChange={this.handleOnChange.bind(this)}
              />
              <FormTextField
                label="Teléfono"
                name="telephone"
                onChange={this.handleOnChange.bind(this)}
              />
              <FormTextField
                label="Celular"
                name="cellphone"
                onChange={this.handleOnChange.bind(this)}
              />
              <FormTextArea
                label="Mensaje"
                name="comment"
                onChange={this.handleOnChange.bind(this)}
              />
            </FormFieldset>
            <FormActions>
              <Button
                disabled={!this.canSend()}
                onClick={() => this.send(this.state.form)}
              >
                Enviar
              </Button>
            </FormActions>
          </div>
        )}
      </FormContainer>
    );
  }
}
