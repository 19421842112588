import { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Button from 'components/atoms/Button';

class CancelledShift extends Component {
  render() {
    return (
      <div>
        <MobileNavigation />
        <Header />
        <div className="unitDetail container">
          <div className="unitDetail__buy">
            <div className="unitDetail__buyHelp">
              <h2>Turnos de taller</h2>
              <p>
                <strong>Turno cancelado exitosamente.</strong>
              </p>
              <p>
                La asistencia al turno de taller ha sido cancelada. No dudes en
                volver a solicitar un turno a través de{' '}
                <a href="https://territorioyacopini.com.ar/solicitar-turno/nissan">
                  nuestro sistema
                </a>
                .
              </p>

              <Link to="/">
                <Button arrow color="white">
                  Volver
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default CancelledShift;
