import './Select.scss';

const Select = ({ value, values, name, onChange }) => (
  <select onChange={onChange} value={value} name={name}>
    <option value="">Selecciona una opción</option>
    {values.map(value => (
      <option value={value.value} key={value.value}>
        {value.name}
      </option>
    ))}
  </select>
);

export default Select;
