const defaultState = {
  list: [],
  loading: false,
  plan: undefined,
  purchasing: false,
  purchaseSuccess: false,
  purchaseError: false,
  transaction: null
};

export default function models(state = defaultState, action) {
  switch (action.type) {
    case 'REQUEST_PLAN_LIST':
      return Object.assign({}, state, { loading: true });

    case 'RECEIVE_PLAN_LIST':
      return Object.assign({}, state, { loading: false, list: action.list });

    case 'REQUEST_PLAN':
      return Object.assign({}, state, { loading: true });

    case 'RECEIVE_PLAN':
      return Object.assign({}, state, {
        loading: false,
        plan: action.plan
      });

    case 'INIT_SUBSCRIPTION':
      return Object.assign({}, state, {
        plan: undefined,
        purchasing: false,
        purchaseError: false,
        purchaseSuccess: false
      });

    case 'REQUEST_SUBSCRIPTION':
      return Object.assign({}, state, {
        purchasing: true,
        purchaseError: false
      });

    case 'RETRY_SUBSCRIPTION':
      return Object.assign({}, state, {
        purchaseError: false
      });

    case 'SUBSCRIPTION_SUCCESSFUL':
      return Object.assign({}, state, {
        purchasing: false,
        purchaseSuccess: true,
        purchaseError: false,
        promotion: undefined,
        transaction: action.payload
      });

    case 'REQUEST_SUBSCRIPTION_ERROR':
      return Object.assign({}, state, {
        purchasing: false,
        purchaseError: action.error
      });

    default:
      return state;
  }
}
