import { Component } from 'react';
import menuImage from 'images/menu.svg';
import menuOnImage from 'images/menu_on.svg';
import Navigation from 'components/molecules/Navigation';
import facebookLogo from 'images/sociallink_facebook_dark.svg';
import instagramLogo from 'images/sociallink_instagram_dark.svg';
import twitterLogo from 'images/sociallink_twitter_dark.svg';
import youtubeLogo from 'images/sociallink_youtube_dark.svg';
import whatsappLogo from 'images/sociallink_whatsapp_dark.svg';
import './MobileNavigation.scss';

class MobileNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
  }

  render() {
    const status = this.state.open ? 'open' : null;

    return (
      <div
        className={
          'mobileNavigation ' + (status ? ' mobileNavigation-' + status : '')
        }
      >
        <div className="mobileNavigation__button">
          <button onClick={() => this.setState({ open: true })}>
            <img src={menuImage} alt="" />
          </button>
        </div>
        <div className="mobileNavigation__navigation">
          <div className="mobileNavigation__main_navigation">
            <button onClick={() => this.setState({ open: false })}>
              <img src={menuOnImage} alt="" />
            </button>
            <Navigation />
          </div>
          <div className="mobileNavigation__footer">
            <div className="mobileNavigation__address">
              <p>San Martín Sur 719.</p>
              <p>Godoy Cruz, Mendoza.</p>
            </div>
            <div className="mobileNavigation__social">
              <a
                href="https://www.facebook.com/YacopiniNissan/"
                target="_blank"
                rel="noopener noreferrer"
                className="mobileNavigation__social__link"
              >
                <img src={facebookLogo} alt="Facebook" />
              </a>
              <a
                href="https://twitter.com/YacopiniNissan"
                target="_blank"
                rel="noopener noreferrer"
                className="mobileNavigation__social__link mobileNavigation__social__link-twitter"
              >
                <img src={twitterLogo} alt="Twitter" />
              </a>
              <a
                href="https://www.instagram.com/yacopininissan/"
                target="_blank"
                rel="noopener noreferrer"
                className="mobileNavigation__social__link mobileNavigation__social__link-instagram"
              >
                <img src={instagramLogo} alt="Instagram" />
              </a>

              <a
                href="https://web.whatsapp.com/send?phone=542614687996 "
                target="_blank"
                rel="noopener noreferrer"
                className="mobileNavigation__social__link mobileNavigation__social__link-whatsapp"
              >
                <img src={whatsappLogo} alt="Whatsapp" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCaII_9QKsBYfnlUwhrQaE1g"
                target="_blank"
                rel="noopener noreferrer"
                className="mobileNavigation__social__link mobileNavigation__social__link-youtube"
              >
                <img src={youtubeLogo} alt="YouTube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileNavigation;
