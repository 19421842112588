import { Component } from 'react';
import Button from 'components/atoms/Button';
import { currency } from 'helpers/format';

import './VehicleVersionCard.scss';

class VehicleVersionCard extends Component {
  render() {
    const {
      vehicle,
      showPrice,
      stockCallback,
      priceCallback
    } = this.props;

    let priceBlock;
    let discountsBlock;
    let actionsBlock;

    if (showPrice && vehicle.start_price) {
      priceBlock = (
        <div className="vehicleVersionCard__price">
          <h5>Desde</h5>
          <h2>{currency(vehicle.start_price, vehicle.currency)}</h2>
        </div>
      );
    } else if (showPrice === false) {
      priceBlock = (
        <div className="vehicleVersionCard__price">
          Completá el formulario para consultar nuestros precios.
        </div>
      );
    }

    if (
      showPrice &&
      (vehicle.web_discount > 0 || vehicle.model_year_discount > 0)
    ) {
      discountsBlock = (
        <div className="vehicleCard__discounts">
          {vehicle.web_discount && (
            <div className="vehicleCard__discount__item">
              Descuento Web: <strong>-{currency(vehicle.web_discount, vehicle.currency)}</strong>
            </div>
          )}
          {vehicle.model_year_discount && (
            <div className="vehicleCard__discount__item">
              Descuento por MY:{' '}
              <strong>-{currency(vehicle.model_year_discount, vehicle.currency)}</strong>
            </div>
          )}
        </div>
      );
    }

    if (showPrice) {
      actionsBlock = (
        <div className="vehicleVersionCard__actions">
          <Button
            link={`https://www.nissan.com.ar`}
            target="_blank"
            arrow="true"
            color="gray"
            type="full"
          >
            Ver caracteristicas
          </Button>
          <Button arrow="true" type="full" onClick={stockCallback}>
            Consultar stock
          </Button>
        </div>
      );
    } else {
      actionsBlock = (
        <div className="vehicleVersionCard__actions">
          <Button
            link={`https://www.nissan.com.ar`}
            target="_blank"
            arrow="true"
            color="gray"
            type="full"
          >
            Ver caracteristicas
          </Button>
          <Button arrow="true" type="full" onClick={priceCallback}>
            Ver stock y precios
          </Button>
        </div>
      );
    }

    return (
      <div className={'vehicleVersionCard '}>
        <div className="vehicleVersionCard__container">
          <div className="vehicleVersionCard__title">
            <h3>{vehicle.name}</h3>
          </div>

          {priceBlock}
          <div className="vehicleCard__priceDetail">{discountsBlock}</div>

          {actionsBlock}
        </div>
      </div>
    );
  }
}

export default VehicleVersionCard;
