const defaultState = {
  popups: null,
  heros: null,
};

export default function parameters(state = defaultState, action) {
  switch (action.type) {
    case 'REQUEST_PARAMETERS':
      return Object.assign({}, state, { popups: null, heros: null });

    case 'PARAMETERS_RECEIVED':
      return Object.assign({}, state, { ...action.payload });

    default:
      return state;
  }
}
