import './SecondaryNavigation.scss';

const SecondaryNavigation = () => (
  <nav className="secondaryNavigation">
    <a
      href="https://www.territorioyacopini.com.ar/usados/"
      className="navigation__link"
    >
      Usados
    </a>
    <a
      href="https://www.territorioyacopini.com.ar/solicitar-turno/nissan"
      className="navigation__link"
    >
      Turnos de taller
    </a>
  </nav>
);

export default SecondaryNavigation;
