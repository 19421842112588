import { NavLink } from 'react-router-dom';
import Button from 'components/atoms/Button';
import { currency } from 'helpers/format';

import { url } from 'helpers/format';

import './UnitItem.scss';

const UnitItem = props => {
  const { vehicle } = props;

  return (
    <div className="unitItem">
      <div className="unitItem__data">
        <h4>{vehicle.name}</h4>
        <h3 className="unitItem__data__price">{currency(vehicle.price, vehicle.currency)}</h3>
        {vehicle.special_discount > 0 && (
          <p className="unitItem__data__discount">
            Con descuento especial de{' '}
            <strong> -{currency(vehicle.special_discount, vehicle.currency)}</strong>
          </p>
        )}
        {vehicle.special_discount === '0' && vehicle.web_discount > 0 && (
          <p className="unitItem__data__discount">
            Con descuento web de{' '}
            <strong> -{currency(vehicle.web_discount, vehicle.currency)} </strong>
          </p>
        )}
        <p>{props.children}</p>
      </div>
      <div className="unitItem__actions">
        <NavLink
          to={`/detalle/${vehicle.id}/${url(vehicle.name)}`}
          className="navigation__link"
          activeClassName="navigation__link--active"
        >
          <Button>Reservar online</Button>
        </NavLink>
      </div>
    </div>
  );
};

export default UnitItem;
