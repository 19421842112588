import { NavLink } from 'react-router-dom';

import './Navigation.scss';

const Navigation = () => (
  <nav className="navigation">
    <NavLink
      to="/vehiculos"
      className="navigation__link"
      activeClassName="navigation__link--active"
    >
      Vehículos
    </NavLink>

    <NavLink
      to="/oportunidades"
      className="navigation__link"
      activeClassName="navigation__link--active"
    >
      Oportunidades
    </NavLink>

    <NavLink
      to="/planes-de-ahorro"
      className="navigation__link"
      activeClassName="navigation__link--active"
    >
      Planes de ahorro
    </NavLink>

    <a
      href="https://www.territorioyacopini.com.ar/solicitar-turno/nissan"
      target="_blank"
      rel="noopener noreferrer"
      className="navigation__link"
    >
      Postventa
    </a>

    <NavLink
      to="/contacto"
      className="navigation__link"
      activeClassName="navigation__link--active"
    >
      Contacto
    </NavLink>
  </nav>
);

export default Navigation;
