import { Component } from 'react';
import { connect } from 'react-redux';
import Navigation from 'components/molecules/Navigation';
import SecondaryNavigation from 'components/molecules/SecondaryNavigation';
import Logo from 'components/atoms/Logo';
import Modal from 'components/molecules/Modal';

import yacopiniLogo from 'images/yacopini-logo-black.png';
import './Header.scss';

import { fetchParameters } from 'actions/parameters';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupDisplayed: !!sessionStorage.getItem('popup_displayed')
    };

    props.fetchParameters();
  }

  render() {
    const { popups } = this.props;
    const { popupDisplayed } = this.state;

    const index = Math.floor(Math.random() * popups?.length);
    const popup = popups ? popups[index] : null;

    if (popup && !popupDisplayed) {
      sessionStorage.setItem('popup_displayed', 'true');
    }

    return (
      <div>
        {popup && !popupDisplayed && (
          <Modal open={true}>
            <a href={popup.url}>
              <img
                src={`//territorioyacopini.com.ar${popup.images[0].path}`}
                alt=""
              />
            </a>
          </Modal>
        )}
        <header className="header">
          <div className="container">
            <div className="header__logo">
              <Logo />
            </div>
            <div className="header__title">
              <img src={yacopiniLogo} height="62" alt="Yacopini Nissan" />
              <h1>Yacopini Nissan</h1>
            </div>
            <div className="header__nav">
              {/* <div className="header__mobile-menu">
              <img src={menuImage} width="49" alt="Nissan" />
            </div>*/}
              <Navigation />
            </div>
          </div>

          <div className="header__secondary-nav">
            <div className="container">
              <div className="header__secondary-nav__col">
                <SecondaryNavigation />
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    popups: state.parameters.popups,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchParameters: () => {
      dispatch(fetchParameters());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
