import ExpirationBox from './ExpirationBox';

const DisplayPlanCoupon = ({ planCoupon }) => {
  if (!planCoupon) {
    return null;
  }

  return (
    <div id="planBenefitsBox">
      <div className="benefits__header">
        <h4>Código de beneficios: {planCoupon.name}</h4>
      </div>
      <ExpirationBox coupon={planCoupon} />
      <div className="benefits__salesman">
        <div
          className="benefits__salesman__avatar"
          style={{
            backgroundImage: `url(https://territorioyacopini.com.ar${planCoupon.salesman.image})`
          }}
        >
          {planCoupon.salesman.image
            ? ''
            : planCoupon.salesman.name.charAt(0) +
              planCoupon.salesman.lastname.charAt(0)}
        </div>
        <div className="benefits__salesman__name">
          Tu asesor:
          <br />
          <strong>
            {planCoupon.salesman.name} {planCoupon.salesman.lastname}.{' '}
          </strong>
          {planCoupon.salesman.whatsapp_number && (
            <span className="benefits__salesman__contact">
              <a
                href={`https://web.whatsapp.com/send?phone=${planCoupon.salesman.whatsapp_number}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                WhatsApp
              </a>
            </span>
          )}
        </div>
      </div>
      <ul>
        {planCoupon.benefits.map((benefit, i) => (
          <li key={i}>{benefit}</li>
        ))}
      </ul>
    </div>
  );
};

export default DisplayPlanCoupon;
