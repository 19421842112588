import { Component } from 'react';
import Select from 'components/atoms/Select';
import Label from 'components/atoms/Label';
import './FormSelect.scss';

class FormSelect extends Component {
  render() {
    const {
      status,
      size,
      label,
      helpText,
      error,
      values,
      value,
      ...etc
    } = this.props;
    /* STATUS = "error", "disabled" */

    return (
      <div
        className={
          'formSelect ' +
          (size ? 'formSelect-' + size + ' ' : '') +
          (status ? 'formSelect-' + status + ' ' : '')
        }
      >
        <Label>{label}</Label>
        <Select {...etc} value={value} values={values} />
        {helpText && <div className="formSelect__helpText">{helpText}</div>}
        {error && status === 'error' && (
          <div className="formSelect__error">{error}</div>
        )}
      </div>
    );
  }
}

export default FormSelect;
