import { Component } from 'react';
import TextArea from 'components/atoms/TextArea';
import Label from 'components/atoms/Label';
import './FormTextArea.scss';

class FormTextArea extends Component {
  render() {
    const {
      status,
      size,
      label,
      placeholder,
      helpText,
      error,
      ...etc
    } = this.props;

    /* STATUS = "error", "disabled" */

    return (
      <div
        className={
          'formTextArea ' +
          (size ? 'formTextArea-' + size + ' ' : '') +
          (status ? 'formTextArea-' + status + ' ' : '')
        }
      >
        <Label>{label}</Label>
        <TextArea placeholder={placeholder} {...etc} />
        {helpText && <div className="formTextArea__helpText">{helpText}</div>}
        {error && status === 'error' && (
          <div className="formTextArea__error">{error}</div>
        )}
      </div>
    );
  }
}

export default FormTextArea;
