import { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from 'containers/Home';
import Vehicles from 'containers/Vehicles';
import Contact from 'containers/Contact';
import Promotions from 'containers/Promotions';
import VehicleDetail from 'containers/VehicleDetail';
import UnitDetail from 'containers/UnitDetail';
import Buy from 'containers/Buy';
import BuySuccess from 'containers/BuySuccess';
import ConfirmedShift from 'containers/ConfirmedShift';
import CancelledShift from 'containers/CancelledShift';
import Plans from 'containers/Plans';
import PlanDetail from 'containers/PlanDetail';
import PlanInstallmentsDetail from 'containers/PlanInstallmentsDetail';
import PlanInstallmentsSimulator from 'containers/PlanInstallmentsSimulator';
import PlanBuy from 'containers/PlanBuy';
import PlanBooking from 'containers/PlanBooking';
import PlanBuySuccess from 'containers/BuyPlanSuccess';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/vehiculos" component={Vehicles} />
          <Route
            path="/tu-proximo-nissan/:family_id/:name"
            component={VehicleDetail}
          />
          <Route path="/detalle/:model_id/:name" component={UnitDetail} />
          <Route path="/reservar/:model_id/:name" component={Buy} />
          <Route path="/comprar" component={Buy} />
          <Route path="/comprarCongrats" component={BuySuccess} />
          <Route path="/contacto" component={Contact} />
          <Route path="/oportunidades" component={Promotions} />
          <Route
            path="/turno-confirmado/:code"
            exact
            component={ConfirmedShift}
          />
          <Route
            path="/turno-cancelado/:code"
            exact
            component={CancelledShift}
          />
          <Route path="/planes-de-ahorro" exact component={Plans} />
          <Route
            path="/planes-de-ahorro/detalle/:id/:name"
            component={PlanDetail}
          />
          <Route
            path="/planes-de-ahorro/suscribirse/:id/:name"
            exact
            component={PlanBuy}
          />
          <Route
            path="/planes-de-ahorro/suscribirse/:id/con-beneficios/:code"
            exact
            component={PlanBuy}
          />
          <Route
            path="/planes-de-ahorro/reservar/:id/:name"
            exact
            render={props => <PlanBuy {...props} enableBooking />}
          />
          <Route
            path="/planes-de-ahorro/reservar/:id/:name/:subscription"
            exact
            render={props => <PlanBooking {...props} />}
          />
          <Route
            path="/planes-de-ahorro/reservar/:id/con-beneficios/:code"
            exact
            render={props => <PlanBuy {...props} enableBooking />}
          />
          <Route
            path="/planes-de-ahorro/cuotas/:id/:name"
            component={PlanInstallmentsDetail}
          />
          <Route
            path="/planes-de-ahorro/simulador/:id/:name"
            component={PlanInstallmentsSimulator}
          />
          <Route
            path="/planes-de-ahorro-suscribirse-congrats"
            component={PlanBuySuccess}
          />
          <Route component={Home} />
        </Switch>
      </Router>
    );
  }
}

export default App;
