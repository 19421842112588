import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Button from 'components/atoms/Button';
import './FamilyPromotionCard.scss';

import { currency } from 'helpers/format';
import { getImage as getVehicleImage } from 'helpers/vehicle';

class FamilyPromotionCard extends Component {
  render() {
    const { size, vehicle, link, callToAction, buttonCallback } = this.props;

    let vehicleImage = getVehicleImage(vehicle.family || vehicle.name);

    return (
      <div
        className={
          'familyPromotionCard ' +
          (size ? 'familyPromotionCard-' + size + ' ' : '')
        }
      >
        <div className="familyPromotionCard__container">
          <div className="familyPromotionCard__title">
            <h3> {vehicle.family}</h3>
          </div>
          {vehicle.highestDiscount > 0 && (
            <div className="familyPromotionCard__price">
              <h5>Descuentos hasta </h5>
              <h2>- {currency(vehicle.highestDiscount, vehicle.highestDiscountCurrency)}</h2>
              <p />
            </div>
          )}
          {vehicle.start_price > 0 && (
            <div className="familyPromotionCard__price">
              <h5>Desde</h5>
              <h2>{currency(vehicle.start_price, vehicle.currency)}</h2>
            </div>
          )}

          <div className="familyPromotionCard__description">
            <strong>
              Hay {vehicle.offers.length}{' '}
              {vehicle.offers.length === 1
                ? 'unidad disponible'
                : 'unidades disponibles'}
            </strong>{' '}
            con precio especial. Selecciona la unidad que te interese y reservá
            online en solo unos minutos.
          </div>

          <div className="familyPromotionCard__image">
            <img src={vehicleImage} alt="Model" />
          </div>
          <div className="familyPromotionCard__actions">
            {link && (
              <NavLink to={link}>
                <Button arrow="true" type="full">
                  {callToAction}
                </Button>
              </NavLink>
            )}
            {buttonCallback && (
              <Button arrow="true" type="full" onClick={buttonCallback}>
                {callToAction}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FamilyPromotionCard;
