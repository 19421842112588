import CarouselNavButton from 'components/atoms/CarouselNavButton';
import CarouselSlide from 'components/molecules/CarouselSlide';
import { useState } from 'react';
import './Carousel.scss';

function Carousel({ noNav, items }) {
  const [active, setActive] = useState(0);

  if (!items) {
    return null;
  }

  let nav;
  if (!noNav && items?.length > 1) {
    nav = (
      <div className="carousel__nav">
        <CarouselNavButton onClick={() => setActive(active-1 < 0 ? items.length - 1 : active-1)} />
        <CarouselNavButton type="right" onClick={() => setActive(active+1 === items.length ? 0 : active+1)} />
      </div>
    );
  }

  const activeItem = items[active];

  return (
    <div
      className={'carousel ' + (noNav ? ' carousel-noNav' : '')}
    >
      {nav}
      <div className="carousel__slides">
        <CarouselSlide
          status="active"
          type="dark"
          src={`https://territorioyacopini.com.ar/${activeItem.images[0].path}`}
          title={activeItem.title}
          subtitle={activeItem.subtitle}
          link={activeItem.url}
        />
      </div>
    </div>
  );
}


export default Carousel;
