import { NavLink } from 'react-router-dom';
import Button from 'components/atoms/Button';
import PlanTable from 'components/organisms/PlanTable';
import { currency } from 'helpers/format';

export default ({ plan }) => (
  <div className="planDetail__buy">
    <div className="planDetail__buyHelp">
      <h2>FINANCIÁ TU {plan.family.toUpperCase()} CON NISSAN PLAN DE AHORRO</h2>
      <p>
        Accedé a tu {plan.family} 0 km. en cuotas accesibles y en pesos, con un
        valor móvil financiado igual al precio de lista bonificado. ¡Conocé con
        antelación el valor de tus cuotas y planificá la compra de tu próximo
        Nissan!
      </p>
    </div>

    <div className="planDetail__buyInfo">
      <h5>Detalle de la Cuota de adhesión</h5>
      <h1>{plan.name}</h1>

      <PlanTable>
        <div className="priceTable__row">
          <div className="priceTable__item">Cuota pura</div>
          <div className="priceTable__price">
            {currency(plan.pure_installment)}
          </div>
        </div>
        <div className="priceTable__row">
          <div className="priceTable__item">Gastos administrativos</div>
          <div className="priceTable__price">
            {currency(plan.administrative_charge)}
          </div>
        </div>
        <div className="priceTable__row">
          <div className="priceTable__item">
            Derecho de adhesión (cuota {plan.inscription_charge_from} a{' '}
            {plan.inscription_charge_to}){' '}
          </div>
          <div className="priceTable__price">
            {currency(plan.inscription_charge)}
          </div>
        </div>
        <div className="priceTable__row">
          <div className="priceTable__item">Impuesto débitos y créditos</div>
          <div className="priceTable__price">
            {currency(plan.stamping_charge)}
          </div>
        </div>
        <div className="priceTable__row">
          <div className="priceTable__item">Seguro de vida</div>
          <div className="priceTable__price">
            {currency(plan.life_insurance_charge)}
          </div>
        </div>
        <div className="priceTable__row">
          <div className="priceTable__item">Incluye IVA 21%</div>
        </div>
      </PlanTable>
    </div>
    <div className="planDetail__buyInfo">
      <h5>Valor Móvil Financiado </h5>
      <h1>{currency(plan.vehicle_price)}</h1>
    </div>
    <hr />
    <div className="planDetail__buyCTA">
      <NavLink to={plan.installments_link}>
        <Button arrow color="gray">
          Ver detalle de cuotas
        </Button>
      </NavLink>
      <NavLink to={plan.simulate_link}>
        <Button arrow color="gray">
          Calcular aumento de cuotas
        </Button>
      </NavLink>
      <NavLink to={plan.subscribe_link}>
        <Button arrow>Suscribirse a este plan</Button>
      </NavLink>
    </div>
  </div>
);
