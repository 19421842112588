import { NavLink } from 'react-router-dom';
import logoImage from 'images/logo.png';
//import './Logo.scss';

const Logo = () => (
  <div className="logo">
    <NavLink to="/" exact>
      <img src={logoImage} width="90" alt="Nissan" />
    </NavLink>
  </div>
);

export default Logo;
