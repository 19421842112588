import { Component } from 'react';
import Button from 'components/atoms/Button';

import './Modal.scss';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open
    };
  }

  render() {
    const status = this.state.open ? 'open' : 'closed';

    return (
      <div className={'modal ' + (status ? ' modal-' + status : '')}>
        <div className="modal__overlay" />
        <div className="modal__actions">
          <div className="container">
            <div className="col">
              <Button onClick={() => this.setState({ open: false })}>
                Cerrar
              </Button>
            </div>
          </div>
        </div>
        <div className="modal__body">
          <div className="container">
            <div className="col">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
