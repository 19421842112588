import { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import MobileNavigation from 'components/molecules/MobileNavigation';
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';
import NoPlan from 'containers/PlanBuy/NoPlan';
import PlanDetailHome from './PlanDetailHome';
import { fetchPlanWithDetail, initSubscription } from 'actions/plans';
import { currency } from 'helpers/format';
import { getHero as getVehicleImage } from 'helpers/vehicle';

import './PlanDetail.scss';

class PlanDetail extends Component {
  componentDidMount() {
    const { plan } = this.props;

    if (
      !plan ||
      plan.id !== this.props.match.params.id ||
      !plan.installment_list
    ) {
      this.props.fetchPlan(this.props.match.params.id);
    }
  }

  render() {
    const { plan, loading } = this.props;

    if (loading || plan === undefined) {
      return <Loader />;
    }

    if (plan === null) {
      return this.renderNoPlan();
    }

    const heroImage = getVehicleImage(plan.family);

    return (
      <div>
        <MobileNavigation />
        <Header />

        <div className="planDetail container">
          <div className="planDetail__hero">
            <div className="planDetail__hero__data">
              <h4>Nissan Plan de Ahorro</h4>
              <h1>{plan.name}</h1>
              <p>
                Modelo: <strong>{plan.model}</strong>
                <br />
                Operatoria: <strong>{plan.integration}</strong>
                <br />
                Meses: <strong>{plan.installments}</strong>
                <br />
                Precio / Valor Móvil:{' '}
                <strong>{currency(plan.vehicle_price)}</strong>
                <br />
                Cuota de adhesión:{' '}
                <strong>{currency(plan.initial_installment)}</strong>
              </p>
              <NavLink to={plan.subscribe_link}>
                <Button arrow>Suscribirse a este plan</Button>
              </NavLink>

              <NavLink to={plan.installments_link}>
                <Button arrow color="gray">
                  Ver detalle de cuotas
                </Button>
              </NavLink>

              <NavLink to={plan.simulate_link}>
                <Button arrow color="gray">
                  Simular aumento de cuotas
                </Button>
              </NavLink>
            </div>
            <div className="planDetail__hero__image">
              <img src={heroImage} alt="Kicks" />
            </div>
          </div>
          <hr />
          <PlanDetailHome plan={plan} />
        </div>

        <Footer />
      </div>
    );
  }

  renderNoPlan() {
    return <NoPlan />;
  }
}

const mapStateToProps = state => {
  return {
    plan: state.plans.plan,
    loading: state.plans.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPlan: id => {
      dispatch(initSubscription());
      dispatch(fetchPlanWithDetail(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanDetail);
